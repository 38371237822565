@tailwind base;
@tailwind components;
@tailwind utilities;
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");

body::-webkit-scrollbar {
  width: 0.5em;
}

body::-webkit-scrollbar-track {
  background-color: #312d4b;
}

body::-webkit-scrollbar-thumb {
  background-color: black;
  outline: slategrey;
}

@layer utilities {
  .bg-primary-100 {
    background: linear-gradient(
        0deg,
        rgba(255, 255, 255, 0.8),
        rgba(255, 255, 255, 0.8)
      ),
      #7367f0;
  }

  .bg-primary-200 {
    background: linear-gradient(
        0deg,
        rgba(255, 255, 255, 0.6),
        rgba(255, 255, 255, 0.6)
      ),
      #7367f0;
  }

  .bg-primary-300 {
    background: linear-gradient(
        0deg,
        rgba(255, 255, 255, 0.4),
        rgba(255, 255, 255, 0.4)
      ),
      #7367f0;
  }

  .bg-primary-400 {
    background: linear-gradient(
        0deg,
        rgba(255, 255, 255, 0.2),
        rgba(255, 255, 255, 0.2)
      ),
      #7367f0;
  }

  .bg-primary-600 {
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)),
      #7367f0;
  }

  .bg-primary-700 {
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.15), rgba(0, 0, 0, 0.15)),
      #7367f0;
  }

  .bg-primary-800 {
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)),
      #7367f0;
  }

  .bg-primary-900 {
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.25), rgba(0, 0, 0, 0.25)),
      #7367f0;
  }
}
